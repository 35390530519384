<template>
  <div class="FlateCart">
    <v-container>
      <v-row>
        <v-col align="left" cols="6">
          <v-checkbox
          v-model="allSelected"
          color="primary"
          label='Select all'
          @click="selectAll(items)"
          ></v-checkbox>
        </v-col>
        <v-col align="right" cols="6">
          <v-btn text color="error" v-on:click="delAll" >{{$t('Delete')}} </v-btn>
        </v-col>
      </v-row>

      <RouteInformation
        :dialog="notifRoute"
        :ToDest="routeInformation.route_loc"
        :ToOrig="routeInformation.route_loc"
        :destination="routeInformation.destination_loc"
        :origin="routeInformation.original_loc"
        :routeTitle="routeInformation.cart_item_name"
        :orig="routeInformation.orig_loc"
        :dest="routeInformation.dest_loc"
        :titleheadlineCard="routeInformation.route_loc"
        :btnClose="closeRouteInformation"
      />
      <v-row  no-gutters v-for="item in items" :key="item.fare_id">
        <v-col cols="1">
          <v-checkbox
              v-model ="selected"
              color="primary"
              :label="item.cart_item_name "
              :value="item"
              @click="select"
          ></v-checkbox>
        </v-col>
        <v-col cols="11">

        <!-- card  flatecart-->
        <div v-if="item.fare_type_buy == 'flatefare'" class="component-1">
          <p class="route-1">{{ item.route_name }}</p>
          <div class="flex-wrapper-three">
            <p class="bth"> BTH </p>
            <div class="relative-wrapper-one">
              <img
                alt=""
                class="vector"
                :src="require('@/assets/buy-ticket/bus-ticket.svg')"
              />
            </div>
            <p class="ct"> CT </p>
          </div>
          <div class="flex-wrapper-four">
            <p class="bn-thanh">{{ item.fare_origin_name }}</p>
            <p class="bn-bbc">{{ item.fare_destination_name }}</p>
          </div>
          <img
            alt=""
            class="vector-7"
            :src="require('@/assets/buy-ticket/vektor.svg')"
          />
          <div class="flex-wrapper-five">
            <div class="flex-wrapper-six">
              <p v-on:click="clickRouteInformation(item)" class="route-information">
                {{$t("RouteInformation")}}
              </p>
              <p class="flat-fare-ticket-is-valid-for-24-hours">
                {{$t("SingleTripTicketIsValidForTwentyFourHours")}}<!-- {{ time }} -->
              </p>
            </div>
            <p class="num-135000-v-nd">{{ item.cart_original_price }} {{ item.fare_currency}}</p>

            <v-icon class="delete-card danger--text" v-on:click="delItem(item)">mdi-delete</v-icon>
            <MinusPlusButtonCart  v-model="item.cart_qty"/>
            </div>
          </div>
        <!-- end card  flatecart-->

        <!-- card  multidays-->
        <div v-if="item.fare_type_buy == 'multidays'" class="component-1">
          <p class="all-route-1">{{$t("AllRoute")}}</p>
          <div class="flex-wrapper-name-multidays">
            <p class="DaysTicket"> 3 {{$t("DaysTicket")}} </p>
            <p class="price-multidays "> 40000 VND  </p>
          </div>
          <div class="flex-wrapper-trip-multidays">
            <v-row class="row-trip-multidays">
             <div class="titik"></div>
             <p class="trip-exp">2 {{$t("Trips_day")}}</p>
            <div class="titik"></div>
            </v-row>
          </div>
          <img
            alt=""
            class="vector-7-multidays"
            :src="require('@/assets/buy-ticket/vektor.svg')"
          />
          <div class="flex-wrapper-route-information">
            <div class="flex-wrapper-route-information-2">
              <p v-on:click="clickRouteInformation(item)" class="route-information">
                {{$t("RouteInformation")}}
              </p>
              <p class="flat-fare-ticket-is-valid-for-24-hours">
                {{$t("SingleTripTicketIsValidForTwentyFourHours")}}<!-- {{ time }} -->
              </p>
            </div>

            <v-icon class="delete-card-multidays danger--text" v-on:click="delItem(item)">mdi-delete</v-icon>
            <MinusPlusButtonCart  v-model="item.cart_qty"/>
            </div>
          </div>
        <!-- end card  multidays-->


        </v-col>
      </v-row>

      <v-container>
      <!-- <v-list-item class="grow"> -->
        <v-row justify="end" >
          <v-col align="left">
            <p class="primary--text">{{$t("Total")}}:</p>
          </v-col>
          <v-col align="right">
          <p class="black--text">{{this.total}}</p>
          </v-col>
        </v-row>
      <!-- </v-list-item> -->
      </v-container>

      <div class="footer">
        <v-container>
          <v-btn rounded width="100%" class="primary" v-on:click="checkOut(selected)" >{{$t('CheckOut')}} </v-btn>
        </v-container>
      </div>
     </v-container>
  </div>
</template>

<script>
import store from "@/store";
import MinusPlusButtonCart from '@/components/MinPlusButtonCart.vue'
import RouteInformation from '@/components/RouteInformation.vue'

export default {
  name: 'FlateCart',
  components: { 
    MinusPlusButtonCart,
    RouteInformation,
  
  },
  data() {
      return {
        notifRoute:false,
        timestamp: "",
        selected: [],
        allSelected: false,
        notifSuccess:false,
        notifFailed:false,
        total:0,
        routeInformation:{},
        // items:[],
        items:[
        {
            fare_type_buy: "flatefare",
            fare_id: 119,
            cart_qty: 2,
            cart_original_price: 10000,
            promo_id: 0,
            fare_merchant_id: "3",
            fare_merchant_name: "HCMC Operator 1",
            fare_name: "Single Trip OP1",
            fare_currency: "VNĐ",
            fare_value: "5000",
            original_value: "5000",
            promotion_name: "",
            promotion_type: "",
            promotion_value: "",
            fare_origin_id: "15",
            fare_destination_id: "17",
            fare_origin_name: "OP1-MCPT-Location A",
            fare_destination_name: "OP1-MCPT-Location C",
            fare_start_date: "2020-07-01 01:02:00",
            fare_end_date: "2020-07-31 01:02:00",
            fare_type: "01",
            created_at: "2020-07-29 08:03:23",
            updated_at: "2020-07-29 08:03:55",
            deleted_at: "",
            fare_user_updated: "HCMC-Operator1",
            fare_uuid: "55505066-bb91-4094-a5df-b06e20a9afb7",
            max_trip: "",
            max_trip_perday: "",
            valid_days: "",
            buy_time_as_expired: "",
            route_id: "1",
            route_name: "route 2",
            to_orig:"Công trường Mê Linh-Thi Sách-Công trường Mê Linh-Tôn Đức Thắng-Hàm Nghi - Phó Đức Chính - Nguyễn Thái Bình - Ký Con - Trần Hưng Đạo-Nguyễn Tri Phương-Trần Phú-Trần Hưng Đạo-Châu Văn Liêm-Hải Thượng Lãn Ông-Trang Tử-Ga Chợ Lớn A",
            to_dest:"Ga Chợ Lớn A-Lê Quang Sung-Nguyễn Hữu Thận-Phạm Đình Hổ-Tháp Mười-Hải Thượng Lãn Ông-Châu Văn Liêm-Nguyễn Trãi-Nguyễn Tri Phương-Trần Phú-Trần Hưng Đạo -Hàm Nghi-Hồ Tùng Mậu-đường nhánh S2-Tôn Đức Thắng-Hai Bà Trưng-Đông Du-Thi Sách-Công trường Mê Linh"
        },
        {
            fare_type_buy: "multidays",
            fare_id: 120,
            cart_qty: 2,
            cart_original_price: 12000,
            promo_id: 0,
            fare_merchant_id: "3",
            fare_merchant_name: "HCMC Operator 1",
            fare_name: "Single Trip OP2",
            fare_currency: "VNĐ",
            fare_value: "6000",
            original_value: "6000",
            promotion_name: "",
            promotion_type: "",
            promotion_value: "",
            fare_origin_id: "15",
            fare_destination_id: "17",
            fare_origin_name: "OP1-MCPT-Location A",
            fare_destination_name: "OP1-MCPT-Location C",
            fare_start_date: "2020-07-01 01:02:00",
            fare_end_date: "2020-07-31 01:02:00",
            fare_type: "01",
            created_at: "2020-07-29 08:03:23",
            updated_at: "2020-07-29 08:03:55",
            deleted_at: "",
            fare_user_updated: "HCMC-Operator1",
            fare_uuid: "55505066-bb91-4094-a5df-b06e20a9afb7",
            max_trip: "",
            max_trip_perday: "",
            valid_days: "",
            buy_time_as_expired: "",
            route_id: "1",
            route_name: "route 2",
            to_orig:"Công trường Mê Linh-Thi Sách-Công trường Mê Linh-Tôn Đức Thắng-Hàm Nghi - Phó Đức Chính - Nguyễn Thái Bình",
            to_dest:"Châu Văn Liêm-Nguyễn Trãi-Nguyễn Tri Phương-Trần Phú-Trần Hưng Đạo -Hàm Nghi-Hồ Tùng Mậu-đường nhánh S2-Tôn Đức Thắng-Hai Bà Trưng-Đông Du-Thi Sách-Công trường Mê Linh"
        }],
        psg_detail :store.state.auth.userData.user_name,
        psg_id :store.state.auth.userData.psg_id,
      }
    },
  
methods:{
    clickRouteInformation(itemRoute) {
      this.routeInformation = itemRoute;
      this.notifRoute=true;
    },
    closeRouteInformation(){
      this.notifRoute=false;
    },
    refreshItem: function(){
          const payload = {
            cart_psg_id:this.psg_id 
            };
      this.$store
        .dispatch("purchaseticket/getCart",payload)
        .then((response) => {
          this.items=[]
          for (let data of response.data){
             this.items.push(data)
             this.selected= []
             this.allSelected= false
             this.total=0
            // console.log("data asa",data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delItem(item){
    const payload = {
      cart_psg_id:this.psg_id ,
      cart_fare_id: item.cart_fare_id,
    };
      this.$store
        .dispatch("purchaseticket/delItem", payload)
        .then((response) => {
          // this.notifSuccess = true
          // this.item.cart_qty = 0
          this.refreshItem
        if (response.status) console.log("success");
        })
        .catch((err) => {
          this.notifFailed = true
          console.log(err);
        });
    },
   delAll(){
    const payload = {
      cart_psg_id:this.psg_id ,
      cart_fare_id:'',
    };
      this.$store
        .dispatch("purchaseticket/delItem", payload)
        .then((response) => {
          // this.notifSuccess = true
          // this.item.cart_qty = 0
          this.refreshItem
        if (response.status) console.log("success");
        })
        .catch((err) => {
          this.notifFailed = true
          console.log(err);
        });
    },
  updateItem(item,cart_qty){
    const payload = {
      cart_psg_id:this.psg_id ,
      cart_fare_uuid: item.cart_fare_uuid,
      cart_qty:''+cart_qty+'',
    };
    this.$store
        .dispatch("purchaseticket/updateItem",payload)
        .then((response) => {
          this.items=[]
          for (let data of response.data){
             this.items.push(data)
            }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  checkOut(selected){
    // console.log(selected)
    const today = new Date();
    const date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
    const time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
    const dateTime = date +''+ time;
    this.timestamp = dateTime;
    const payload = {
      inv_no:'ACASIA-'+this.timestamp+''+this.psg_id+'',
      inv_psg_id:this.psg_id ,
      inv_psg_detail:this.psg_detail ,
      inv_amount:''+this.total+'' ,
      inv_detail:selected ,
      language: this.$i18n.locale ,
    };
      this.$store
        .dispatch("purchaseticket/checkout", payload)
        .then((response) => {
          this.refreshItem()
        if (response.status) //console.log("success");
        this.$router.push({ name: "MyTicket" });
        })
        .catch((err) => {
          this.notifFailed = true
          console.log(err);
        });
    },
          buttonOke(){
        this.notifSuccess = false
        this.item.cart_qty=0;
        if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
      },
      selectAll(item) {
            this.selected =[]
            if (this.allSelected) {
             this.selected = item  
                            var total= 0; //set a variable that holds our total
                var data = this.selected;  //reference the element in the "JSON" aka object literal we want
                var i = 0;
                for (i = 0; i < data.length; i++) {  //loop through the array
                total += data[i].cart_price_after_discount;  //Do the math!
              }this.total= total
            }
            else this.total= 0
      },
      select: function() {
            this.allSelected = false;
                var total= 0; //set a variable that holds our total
                var data = this.selected;  //reference the element in the "JSON" aka object literal we want
                var i = 0;
                for (i = 0; i < data.length; i++) {  //loop through the array
                total += data[i].cart_price_after_discount;  //Do the math!
      }
this.total= total
      },
       
},
// watch:{
//   this.$store.state.auth.userData.user_name
// },
mounted(){
      // const payload = {
      //       cart_psg_id:this.psg_id 
      //       };
      // this.$store
      //   .dispatch("purchaseticket/getCart",payload)
      //   .then((response) => {
      //     this.items=[]
      //     for (let data of response.data){
      //        this.items.push(data)
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }
</script>
<style lang="scss" scoped>

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}


.footer {
  margin-top: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  /* Field */

  box-shadow: 0px 1px 10px 0px
}

.btn-cart{

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

// card
  .component-1 {
    padding: 10px 14px 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: url('~@/assets/buy-ticket/bg-card.svg');
    background-repeat:no-repeat;
    background-size:contain;
    background-position:left;
  }
  .cardCart{

    background-color:#f5fdf7;
    display: flex;
    flex-wrap: nowrap;
  }

  .route-1 {
    color: rgba(134, 134, 134, 1);
    margin-bottom: 1px;
    margin-left: 150px;
  }

  .flex-wrapper-three { 
    margin-top: -10px;
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
  }
  .bth {
    font-size: 24px;
    color: rgba(72, 158, 75, 1);
    &:not(:last-of-type) {
      margin-right: 50px;
    }
  }
  .ct {
    font-size: 24px;
    color: rgba(72, 158, 75, 1);
  }
  .relative-wrapper-one {
    margin-right: 31px;
    position: relative;
  }
  .vector {
    width: 100px;
    position: relative;
  }
  .group {
    position: absolute;
    left: 55px;
    top: -1px;
    transform: rotate(180deg);
  }
  .flex-wrapper-four {
    margin-left: 10px;
    margin-bottom: -40px;
  }
  .bn-thanh {
    margin-top: -15px;
    margin-left: 10px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    right : 122px;
    &:not(:last-of-type) {
      margin-right: 135px;
    }
  }
  .bn-bbc{
    margin-top: -30px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    margin-right: -100px;
    text-align: right;
  }
  .vector-7 {
    width: 44%;
    margin-top: 35px;
    margin-left: 9px;
    margin-bottom: 9px;
  }
  .flex-wrapper-five {
    padding: 0 0 0 17px;
    display: flex;
    align-items: flex-start;
  }
  .flex-wrapper-six {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .route-information {
    font-size: 12px;
    color: rgba(94, 215, 153, 1);
    text-decoration: underline;
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .flat-fare-ticket-is-valid-for-24-hours {
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    font-style: italic;
  }
  .num-135000-v-nd {
    font-size: 12px;
    color: rgba(242, 153, 0, 1);
    margin-top: 4px;
    margin-right: 9px;
  }
  .group-18785 {
    border-radius: 23px;
    height: 29px;
    width: 80px;
    padding: 2px 6px 2px 4px;
    position: relative;
    border: 1px solid rgba(75, 177, 78, 0.35);
  }

  .flex-wrapper-one {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 11px 10px 10px 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 41px;
    top: -1px;
    border: 1px solid rgba(75, 177, 78, 1);
  }
  .flex-wrapper-two {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 11px 10px 10px 10px;
    display: flex;
    height: 30px;
    width: 30px;
    align-items: center;
    position: absolute;
    left: -10px;
    top: -1px;
    border: 1px solid rgba(75, 177, 78, 1);
  }
  .hyphen {
    width: 5px;
    height: 2px;
    background-color: rgba(75, 177, 78, 1);
  }
  .label {
    width: 64px;
    color: rgba(75, 177, 78, 1);
    text-align: center;
    position: relative;
  }
  .btn-plus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: 45px;
  }
  .btn-minus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: -40px;
  }
  .icon-plus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .icon-minus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .input-label{
    top: -27px;
  }
  .onHover{
    cursor: pointer;
  }
// }
// end cart

  .delete-card{
    padding-left: 5px;
    padding-right: 15px;
  }

  .delete-card-multidays{
    padding-left: 40px;
    padding-right: 10px;
  }
  // multidays
  .flex-wrapper-name-multidays { 
    margin-top: -10px;
    padding: 0 0 0 17px;
    display: flex;
    align-items: center;
  }
  .all-route-1 {
    padding: 0 0 0 17px;
    color: rgba(134, 134, 134, 1);
    margin-bottom: 1px;
  }
  .DaysTicket{
    font-size: 24px;
    color: rgba(72, 158, 75, 1);
    &:not(:last-of-type) {
      margin-right: 120px;
    }
  }
  .price-multidays {
    font-size: 12px;
    color: rgba(242, 153, 0, 1);
  }

  .trip-exp{
    margin-top: -3px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
  }

  .titik{
    width: 5px;
    height: 5px;
    background-color: #4BB14E;
    border-radius: 50%;
  }
  .circle-multidays {
    margin-top: -15px;
    margin-left: 10px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    right : 122px;
    &:not(:last-of-type) {
      margin-right: 135px;
    }
  }
  .flex-wrapper-trip-multidays{
    margin-top: -17px;
    padding: 0 0 10px 17px;
    margin-bottom: -40px;
  }

  .row-trip-multidays {
    padding: 0 0 0 17px;
  }

  .vector-7-multidays {
    width: 42%;
    margin-top: 20px;
    margin-left: 9px;
    margin-bottom: 9px;
  }

  .flex-wrapper-route-information {
    padding: 0 0 0 17px;
    display: flex;
    align-items: flex-start;
  }
  .flex-wrapper-route-information-2 {
    margin-right: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  // end multidays
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}


.footer {
  margin-top: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 8%;
  text-align: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  /* Field */

  box-shadow: 0px 1px 10px 0px
}

.btn-cart{

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

// card
  .component-1 {
    padding: 10px 14px 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: url('~@/assets/buy-ticket/bg-card.svg'); 
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
  }
  .cardCart{

    background-color:#f5fdf7;
    display: flex;
    flex-wrap: nowrap;
  }


  .route-1 {
    color: rgba(134, 134, 134, 1);
    margin-bottom: 1px;
    margin-left: 110px;
    font-size: 10px;
  }
  .flex-wrapper-three { 
    margin-top: -10px;
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
  }

  .bth {
    font-size: 16px;
    color: rgba(72, 158, 75, 1);
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  .ct {
    font-size: 16px;
    color: rgba(72, 158, 75, 1);
  }
  .relative-wrapper-one {
    margin-top: -20px;
    margin-right: 28px;
    position: relative;
  }
  .vector {
    position: relative;
    width: 100px;
  }
  .group {
    position: absolute;
    left: 55px;
    top: -1px;
    transform: rotate(180deg);
  }
  .flex-wrapper-four {
    margin-left: 10px;
    margin-bottom: -40px;
  }
  .bn-thanh {
    margin-top: -15px;
    margin-left: 15px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    right : 122px;
    &:not(:last-of-type) {
      margin-right: 80px;
    }
  }
  .bn-bbc{
    margin-top: -30px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    margin-right: -100px;
    text-align: right;
  }
  .vector-7 {
    width: 94%;
    margin-top: 15%;
    margin-left: 9px;
    margin-bottom: 9px;
  }
  .flex-wrapper-five {
    padding: 0 20px 0 17px;
    display: flex;
    align-items: flex-start;
  }
  .flex-wrapper-six {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .route-information {
    font-size: 8px;
    color: rgba(94, 215, 153, 1);
    text-decoration: underline;
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .flat-fare-ticket-is-valid-for-24-hours {
    font-size: 6px;
    color: rgba(75, 177, 78, 1);
    font-style: italic;
  }
  .num-135000-v-nd {
    font-size: 10px;
    color: rgba(242, 153, 0, 1);
    margin-top: 4px;
    margin-right: 9px;
  }
  .group-18785 {
    border-radius: 23px;
    height: 29px;
    width: 80px;
    padding: 2px 6px 2px 4px;
    position: relative;
    border: 1px solid rgba(75, 177, 78, 0.35);
  }

  .flex-wrapper-one {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 11px 10px 10px 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 41px;
    top: -1px;
    border: 1px solid rgba(75, 177, 78, 1);
  }
  .flex-wrapper-two {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 11px 10px 10px 10px;
    display: flex;
    height: 30px;
    width: 30px;
    align-items: center;
    position: absolute;
    left: -10px;
    top: -1px;
    border: 1px solid rgba(75, 177, 78, 1);
  }
  .hyphen {
    width: 5px;
    height: 2px;
    background-color: rgba(75, 177, 78, 1);
  }
  .label {
    width: 64px;
    color: rgba(75, 177, 78, 1);
    text-align: center;
    position: relative;
  }
  .btn-plus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: 45px;
  }
  .btn-minus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: -40px;
  }
  .icon-plus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .icon-minus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .input-label{
    // left: 4px;
    top: -27px;
  }
  .onHover{
    cursor: pointer;
  }
  .delete-card{
    padding-left: 5px;
    padding-right: 10px;
  }
// }
// end cart

  .delete-card-multidays{
    padding-left: 15px;
    padding-right: 15px;
  }

  // multidays
  .all-route-1 {
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 0 0 10px 17px;
    color: rgba(134, 134, 134, 1);
    margin-bottom: 1px;
  }
  .flex-wrapper-name-multidays { 
    margin-top: -15px;
    padding: 0 0 0 17px;
    display: flex;
    align-items: center;
  }
  .DaysTicket{
    font-size: 16px;
    color: rgba(72, 158, 75, 1);
    &:not(:last-of-type) {
      margin-right: 125px;
    }
  }
  .price-multidays {
    font-size: 10px;
    color: rgba(242, 153, 0, 1);
  }

  .trip-exp{
    margin-top: -20px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
  }

  .titik{
    margin-top: -15px;
    width: 5px;
    height: 5px;
    background-color: #4BB14E;
    border-radius: 50%;
  }
  .circle-multidays {
    margin-top: -15px;
    margin-left: 20px;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    right : 122px;
    &:not(:last-of-type) {
      margin-right: 135px;
    }
  }
  .flex-wrapper-trip-multidays{
    // margin-top: -13px;
    padding-left: 29px;
    // margin-bottom: -40px;
  }

  .row-trip-multidays {
    padding: -15px 0 0 17px;
  }

  .vector-7-multidays {
    width: 94%;
    margin-top: -30px;
    margin-left: 9px;
    margin-bottom: 9px;
  }
  
  .flex-wrapper-route-information {
    padding: 0 0 0 17px;
    display: flex;
    align-items: flex-start;
  }
  .flex-wrapper-route-information-2 {
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  // end multidays
}
</style>

